import React, { useEffect } from 'react';
import LayoutStepStart from "../../../hoc/LayoutStepStart/LayoutStepStart";
import { connect } from "react-redux";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const Step4 = ( props ) => {

    useEffect(()=>{
        //props.resetLocalStorage('savingAndInvestments')
    })
    return (

        <LayoutStepStart
            title ='YOUR SOURCES OF INCOME'
            step={4}
            next={retirementCalcLinks.otherSourcesSelector}
        >
            <p>A happy retirement is all about living how you want to live.</p>
            <p>Let’s identify your sources of income to fund the retirement you’ve always dreamed of.</p>
        </LayoutStepStart>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        //resetLocalStorage: (step)=>dispatch(resetLocalStorage(step))
    }
}

export default connect(null, mapDispatchToProps)(Step4);