import React, {Component} from "react";
import classes from './LayoutStepStart.module.scss'
import { Helmet } from "react-helmet";
import Navigation from "../../components/Navigation/Navigation";

export default class LayoutStepStart extends Component{

    render() {

        return(
            <div className='StepStart'>

            <Helmet>
                <title>{this.props.title}</title>
            </Helmet>

            <header>
                <h1>{this.props.title}</h1>
              </header>

            {this.props.children}

            <div className={classes.Step}>
                <span>STEP</span>
                <strong>{this.props.step} of 4</strong>
            </div>

            <Navigation
                type={'start'}
                prev={this.props.prev}
                next={this.props.next}
                isCompleted={true}
            />

        </div>

        )
    }
}